import { debounce } from 'lodash';

export default class Totodile {
  constructor(el, opt = {}) {
    if (typeof el === 'string') {
      el = document.querySelectorAll(el);
    }

    if (!el) {
      return;
    }

    if (!el.length) {
      el = [el];
    }

    this.el = el;
    this.opt = Object.assign({
      delay: 0,
      treshold: 0,
      stagger: 200,
      staggerOrder: (a, b) => {
        return a.boundingClientRect.top - b.boundingClientRect.top ||
          a.boundingClientRect.left - b.boundingClientRect.left;
      },
      prepare: (el, instance) => {},
      run: (el, instance) => {},
    }, opt);

    this.observer = new IntersectionObserver(this.onEnter.bind(this), {
      treshold: this.opt.treshold,
    });

    this.queue = [];
    this.queueProcessing = false;
    this.debouncedProcess = debounce(this.processQueue, 500);

    [...this.el].forEach(el => {
      this.opt.prepare(el, this);
      this.observer.observe(el);
    });
  }

  processQueue(force) {
    if (!force && this.queueProcessing) {
      return;
    }

    this.queueProcessing = true;

    if (!this.queue.length) {
      console.log('finish queue');
      this.queueProcessing = false;
      return;
    }

    this.queue.sort(this.opt.staggerOrder);

    window.setTimeout(() => {
      this.opt.run(this.queue[0].target, this);
      this.queue.shift();

      window.setTimeout(() => this.processQueue(true), this.opt.stagger);
    }, force ? 0 : this.opt.delay);
  }

  onEnter(entries, observer) {
    entries = entries.filter(entry => entry.isIntersecting);

    entries.forEach(entry => {
      this.queue.push(entry);
      observer.unobserve(entry.target);
    });

    console.log(`add ${entries.length} to queue`);

    // this.debouncedProcess();
    this.processQueue();
  }
}
