import { TweenLite, Power4 } from 'gsap';
import Scroll from 'utils/scroll';
import Viewport from 'utils/viewport';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Mq from 'utils/mq';

export default class Header {
  constructor() {
    this.root = document.querySelector('.header');
    this.isSticky = undefined;

    if (!this.root) {
      return;
    }

    this.bind();
  }

  bind() {
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.menu();
    this.onResize();
    this.onScroll();

    Scroll.ee.on('update', this.onScroll);
    Viewport.ee.on('update', this.onResize);
  }

  menu() {
    this.links = this.root.querySelectorAll('.header__menu a');

    [...this.links].forEach(link => {
      link.addEventListener('click', (e) => {
        this.followLink(e, link);
      });
    });
  }

  buildLinkMap() {
    this.map = [];

    [...this.links].forEach(link => {
      const el = document.querySelector(link.getAttribute('href'));
      this.map.push({
        link,
        el,
        y: el.offsetTop,
        active: false,
      })
    });
  }

  onResize() {
    this.buildLinkMap();
  }

  getCurrentSection(y) {
    let result;
    let bestDiff;

    this.map.forEach(current => {
      const diff = Math.abs(y - current.y);

      if (!bestDiff || diff <= bestDiff) {
        bestDiff = diff;
        result = current;
      }
    });

    return result;
  }

  followLink(e, link) {
    e.preventDefault();
    const id = link.getAttribute('href');

    TweenLite.to(window, 1, {
      scrollTo: document.querySelector(id).offsetTop - 70,
      ease: Power4.easeOut,
    });
  }

  onScroll() {
    const { y } = Scroll;

    if (Mq.isLessThan('xlarge')) {
      return;
    }

    const current = this.getCurrentSection(y);

    if (!current.active) {
      [...this.links].forEach(link => link.classList.remove('active'));
      current.link.classList.add('active');
    }

    if (y > Viewport.height && !this.isSticky) {
      this.root.classList.add('sticky');
      TweenLite.killTweensOf(this.root);
      TweenLite.fromTo(this.root, .2, {
        yPercent: -100,
      }, {
        yPercent: 0,
      });
      this.isSticky = true;
    } else if (y < Viewport.height && this.isSticky) {
      this.isSticky = false;
      TweenLite.killTweensOf(this.root);
      TweenLite.fromTo(this.root, .2, {
        yPercent: 0,
      }, {
        yPercent: -100,
        onComplete: () => {
          TweenLite.set(this.root, { clearProps: 'all' });
          this.root.classList.remove('sticky');
        },
      });
    }
  }
}
