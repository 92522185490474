import Mq from 'utils/mq';
import Totodile from 'utils/totodile';
import Parallax from 'utils/parallax';
import { TweenLite, Power4 } from 'gsap';

export default class Screenshots {
  constructor() {
    this.root = document.querySelector('.screenshots');

    if (!this.root) {
      return;
    }

    this.appear();
    // this.parallax();
  }

  appear() {
    const els = this.root.querySelectorAll('.screenshots__list li');

    new Totodile(els, {
      treshold: 150,
      delay: 300,
      stagger: 200,
      prepare(el) {
        TweenLite.set(el, {
          y: 50,
          opacity: 0,
        });
      },
      run(el) {
        TweenLite.to(el, 2.3, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut,
        });
      },
    });
  }

  // parallax() {
  //   new Parallax(this.root.querySelectorAll('[data-parallax]'));
  // }
}
