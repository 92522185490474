import Mq from 'utils/mq';
import { TweenMax, TimelineMax, Power4 } from 'gsap';
import SplitText from 'vendor/splitText';
import nextResize from 'utils/nextResize';
import Parallax from 'utils/parallax';
import Cookies from 'utils/cookies';
import Scroll from 'utils/scroll';

const COOKIE_KEY = 'acceptedCookie';

export default class Hero {
  constructor() {
    this.root = document.querySelector('.hero');
    this.main = document.querySelector('main');
    this.cookie = document.querySelector('.cookie');

    if (!this.root) {
      return;
    }

    if (window.Modernizr.desktop) {
      new Parallax(this.root.querySelectorAll('[data-parallax]'));
    }

    if (Mq.isMoreThan('large')) {
      this.boarding(() => {
        this.showCookieBar();
      });
    } else {
      this.showCookieBar();
    }

    this.cookie.querySelector('.agree').addEventListener('click', this.acceptCookie.bind(this));
  }

  showCookieBar() {
    if (Cookies.getItem(COOKIE_KEY)) {
      return;
    }

    const y = this.cookie.clientHeight
    TweenMax.to(this.main, .6, {
      y,
      ease: Power4.easeInOut,
      onComplete: () => {
        this.cookie.style.position = 'static';
        TweenMax.set(this.main, { clearProps: 'all' });

        Scroll.ee.once('update', this.acceptCookie.bind(this));
      },
    });
  }

  acceptCookie(e) {
    e && e.preventDefault();

    if (this.accepted) {
      return;
    }

    this.accepted = true;

    Cookies.setItem(COOKIE_KEY, 'true', Infinity);
    const y = this.cookie.clientHeight
    TweenMax.to(this.main, .6, {
      y: -y,
      ease: Power4.easeInOut,
      onComplete: () => {
        this.cookie.style.display = 'none';
        TweenMax.set(this.main, { clearProps: 'all' });
      },
    });
  }

  boarding(callback) {
    const whiteLayer = this.root.querySelector('.hero__whitelayer');
    const cover = this.root.querySelector('.hero__cover');
    const phoneLeft = this.root.querySelector('.phone-wrapper-left');
    const phoneRight = this.root.querySelector('.phone-wrapper-right');
    const logo = document.querySelectorAll('.header__logo');
    const menuElements = document.querySelectorAll('.header__menu li');
    const hr = this.root.querySelector('.hero__content hr');
    const splitTitle = new SplitText(document.querySelector('.hero__content h1'), {
      type: 'lines,words',
    });
    const text = this.root.querySelector('.hero__content p');
    const badges = this.root.querySelectorAll('.hero__content .badges li');

    TweenMax.set(splitTitle.lines, { overflow: 'hidden '});
    phoneLeft.classList.remove('animate');
    phoneRight.classList.remove('animate');

    const tl = new TimelineMax({
      delay: 0,
      onComplete: () => {
        TweenMax.set([cover], { clearProps: 'all' });
        nextResize(() => {
          splitTitle.revert();
        });

        callback();
      },
    });

    tl.from(whiteLayer, 1.4, {
      width: '100%',
      display: 'block',
      ease: Power4.easeInOut,
    });

    tl.from(phoneLeft, .8, {
      y: 50,
      opacity: 0,
    }, 1);

    tl.from(phoneRight, .8, {
      y: 50,
      opacity: 0,
      onComplete() {
        phoneLeft.classList.add('animate');
        phoneRight.classList.add('animate');
      },
    }, 1.15);

    tl.from(cover, 1, {
      width: '100%',
      ease: Power4.easeInOut,
    }, 1.6);

    tl.add('content', 2.3);

    tl.from(logo, .6, {
      y: -10,
      opacity: 0,
    }, 'content-=0.3');

    tl.from(hr, .5, {
      width: 0,
      ease: Power4.easeInOut,
    }, 'content');

    tl.staggerFrom(splitTitle.words, .8, {
      yPercent: 100,
      ease: Power4.easeOut,
    }, .07, 'content+=0.3');

    tl.from(text, .5, {
      y: 20,
      opacity: 0,
    }, 'content+=1.3');

    tl.staggerFrom(badges, 1, {
      y: 20,
      opacity: 0,
      ease: Power4.easeOut,
    }, .2, 'content+=1.8');

    tl.add('menu', 'content+=2.4')

    tl.staggerFrom(menuElements, .6, {
      y: -10,
      opacity: 0,
    }, .1, 'menu');
  }

}
