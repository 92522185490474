require('utils/polyfills');
require('vendor/modernizr');
require('vendor/detectizr');
import Features from 'components/features';
import Hero from 'components/hero';
import Screenshots from 'components/screenshots';
import Explain from 'components/explain';
import Header from 'components/header';
import deferred from 'utils/deferred';
import WebFont from 'webfontloader';

class Supportize {
  constructor() {
    Promise
      .all([this.domReady, this.fontReady, this.loadImages])
      .then(this.init.bind(this))
  }

  get loadImages() {
    const dfd = deferred();
    const images = document.querySelectorAll('[data-preload]');
    let toLoad = images.length;

    if (!toLoad) {
      dfd.resolve();
    }

    [...images].forEach(image => {
      if (image.complete) {
        return (--toLoad <= 0) && dfd.resolve();
      }

      image.addEventListener('load', () => {
        return (--toLoad <= 0) && dfd.resolve();
      });
    });

    return dfd.promise;
  }

  get domReady() {
    return new Promise((resolve) => {
      document.addEventListener('DOMContentLoaded', resolve)
    });
  }

  get fontReady() {
    const dfd = deferred();

    try {
      WebFont.load({
        custom: {
          families: ['Graphik'],
        },
        loading: function () { },
        active: function () {
          dfd.resolve();
        },
        inactive: function () {
          dfd.resolve();
        },
      });
    } catch (e) {
      dfd.resolve();
    }

    return dfd.promise;
  }

  showPage() {
    document.documentElement.classList.add('ready');
  }

  init() {
    new Features();
    new Hero();
    new Screenshots();
    new Explain();
    new Header();
    this.showPage();
  }
}

new Supportize();
