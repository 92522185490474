import EventEmitter from 'eventemitter3';

class RAF {
  constructor() {
    this.ee = new EventEmitter();
    this.oldTime = this.now();

    this.bind();
  }

  bind() {
    this.update = this.update.bind(this);
    this.update();
  }

  now() {
    return (typeof performance === 'undefined' ? Date : performance).now();
  }

  update() {
    this.raf = window.requestAnimationFrame(this.update);

    const now = this.now();
    const diff = (now - this.oldTime) / 1000;
    this.oldTime = now;

    this.ee.emit('update', diff);
  }
}

export default new RAF();
