import EventEmitter from 'eventemitter3';
import RAF from 'utils/raf';

class Scroll {
  constructor() {
    this.y = Scroll.getCurrent();
    this.ee = new EventEmitter();

    this.bind();
  }

  bind() {
    this.update = this.update.bind(this);
    this.emit = this.emit.bind(this);

    RAF.ee.on('update', this.update);
    window.addEventListener('scroll', this.emit);
  }

  emit() {
    this.ee.emit('update', this.y);
  }

  static getCurrent() {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  update() {
    this.y = Scroll.getCurrent();
  }
}

export default new Scroll();
