import Flickity from 'flickity';
import Viewport from 'utils/viewport';
import { TimelineMax, TweenMax, Power4 } from 'gsap';
// import Mq from 'utils/mq';

class Slider {
  constructor(el) {
    this.el = el;
    this.slides = this.el.querySelectorAll('li');
    this.current = 0;

    [...this.slides].forEach(slide => slide.style.display = 'none');
    this.getSlide(this.current).style.display = 'flex';

    this.bind();
  }

  bind() {
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);

    this.autoPlay = window.setInterval(this.next, 2200);
  }

  destroy() {
    window.clearInterval(this.autoPlay);

    [...this.slides].forEach(slide => {
      TweenMax.killTweensOf(slide);
      TweenMax.set(slide, { clearProps: 'all' });
      TweenMax.set(slide.querySelectorAll('span, strong'), { clearProps: 'all' });
    });
  }

  getSlide(n) {
    return this.slides[n];
  }

  next() {
    this.goTo(this.current + 1);
  }

  prev() {
    this.goTo(this.current - 1);
  }

  goTo(index) {
    index = index % this.slides.length;

    if (index === this.current) {
      return;
    }

    let newSlide = this.getSlide(index);
    let oldSlide = this.getSlide(this.current);

    const tl = new TimelineMax({
      onComplete: () => {
        this.current = index;
      },
    });

    tl.staggerTo(oldSlide.querySelectorAll('span, strong'), 1, {
      y: -200,
      opacity: 0,
      ease: Power4.easeInOut,
    }, .05);

    tl.set(newSlide, {
      display: 'flex',
    }, 0);

    tl.staggerFromTo(newSlide.querySelectorAll('span, strong'), 1, {
      y: 200,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      ease: Power4.easeInOut,
    }, .05, 0);
  }
}

export default class Features {
  constructor() {
    this.root = document.querySelector('.features');

    if (!this.root) {
      return;
    }

    this.carousel = this.root.querySelector('.features__carousel');

    this.bind();
  }

  get isDesktop() {
    return Viewport.width >= 768;
  }

  bind() {
    this.onResize = this.onResize.bind(this);
    Viewport.ee.on('update', this.onResize);

    this.onResize();
  }

  initFlickity() {
    this.flkty = new Flickity(this.carousel, {
      prevNextButtons: false,
      wrapAround: true,
      // watchCSS: true,
    });
  }

  initSlider() {
    this.slider = new Slider(this.carousel);
  }

  onResize() {
    if (!this.isDesktop) {
      if (this.slider) {
        this.slider.destroy();
        this.slider = undefined;
      }

      !this.flkty && this.initFlickity();
    } else {
      if (this.flkty) {
        this.flkty.destroy();
        this.flkty = null
      }

      !this.slider && this.initSlider();
    }
  }
}
