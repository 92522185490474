import Totodile from 'utils/totodile';
import { TweenMax, Power4 } from 'gsap';
import SplitText from 'vendor/splitText';
import nextResize from 'utils/nextResize';

export default class Explain {
  constructor() {
    this.root = document.querySelector('.explain');

    if (!this.root) {
      return;
    }

    this.appear();
  }

  appear() {
    new Totodile(this.root.querySelector('.explain__intro'), {
      treshold: 50,
      delay: 300,
      prepare(el, toto) {
        toto.title = el.querySelector('h2');
        toto.text = el.querySelector('p');
        toto.titleSplit = new SplitText(toto.title, {
          type: 'lines,words',
        })

        TweenMax.set(toto.titleSplit.lines, {
          overflow: 'hidden',
        });

        TweenMax.set(toto.titleSplit.words, {
          yPercent: 100,
        });
      },
      run(el, toto) {
        TweenMax.staggerTo(toto.titleSplit.words, .6, {
          yPercent: 0,
          ease: Power4.easeOut,
          onComplete: () => {
            nextResize(() => {
              toto.titleSplit.revert();
            });
          },
        }, .1);
      },
    });
  }
}
