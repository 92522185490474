import RAF from 'utils/raf';
import Scroll from 'utils/scroll';
// import Mq from 'utils/mq';
import Viewport from 'utils/viewport';
import { TweenMax, Power4 } from 'gsap';
// import Normalize from 'utils/normalize';
// import offset from 'utils/offset';

export default class Parallax {
  constructor(elements) {
    this.elements = elements;
    this.active = true;

    if (!elements) {
      return;
    }

    // this.mediumHandler = {
    //   match: () => {
    //     this.active = true;
    //   },
    //   unmatch: () => {
    //     this.active = false;
    //     this.clean();
    //   },
    // };

    // Mq.register('medium', this.mediumHandler);
    this.init();
    this.bind();
  }

  bind() {
    this.update = this.update.bind(this);
    this.onResize = this.onResize.bind(this);

    Viewport.ee.on('update', this.onResize);
    RAF.ee.on('update', this.update);
  }

  destroy() {
    Viewport.ee.off('update', this.onResize);
    RAF.ee.off('update', this.update);
  }

  onResize() {
    this.init();
  }

  init() {
    const els = this.elements;
    this.data = [];

    [...els].forEach(el => {
      // let top = offset(el).top;
      this.clean(el);
      el.style.willChange = 'transform';

      let top = el.offsetTop;
      let height = el.clientHeight;
      let speed = parseFloat(el.dataset.parallax || 1);

      let obj = {
        el,
        speed,
        top,
        height,
        mid: top + (height / 2),
      };

      obj.base = this.calculatePercentage(obj, 0);

      this.data.push(obj);
    });

    // this.data = this.dataModifier(this.data);
  }

  clean(el) {
    TweenMax.killTweensOf(el);
    TweenMax.set(el, { clearProps: 'y' });
  }

  cleanAll() {
    for (let i = 0; i < this.data.length; i++) {
      let current = this.data[i];
      this.clean(current.el);
    }
  }

  position() {

  }

  modifier(current, percentage) {
    percentage -= current.base;
    const y = (percentage * 100) * current.speed;

    TweenMax.killTweensOf(current.el);
    TweenMax.to(current.el, 1.5, {
      y,
      ease: window.Power4.easeOut,
    });
  }

  calculatePercentage(el, y = Scroll.y) {
    return (y - el.top + Viewport.height) / (el.height + Viewport.height);
  }

  update() {
    if (!this.active) {
      return;
    }

    for (let i = 0; i < this.data.length; i++) {
      let current = this.data[i];
      let percentage = this.calculatePercentage(current);

      // percentage == 0.01 element start becoming visible
      // percentage == 1 element disappeared

      this.modifier(current, percentage);
    }
  }
}
